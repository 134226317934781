import React from 'react';
import { withRouter } from 'react-router';
import { request } from '../functions/apiRequestWrapper';
import Button from 'react-bootstrap/Button';
import Loader from '../components/Loader';
import Breadcrumb from '../components/Breadcrumb';
import Banner from '../components/Banner';
import ReleaseListItem from '../components/ReleaseListItem';

import { connect } from 'react-redux';
import { getReleases } from '../redux/Releases';
const mapStateToProps = state => {
    return {
        list: state.releases.list,
        count: state.releases.count,
        page: state.releases.page,
        isLoading: state.releases.isLoading,
        error: state.releases.error,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getReleases: (p,cb) => { dispatch(getReleases(p,cb)); }
    }
}

class Releases extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: {title: "Releases"}
        }
    }

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
        if (!this.props.list || this.props.list.length === 0) {
          this.props.getReleases(undefined, this.scrollToRelease);
        } else {
          this.scrollToRelease();
        }
        this.getContent();
    }
    
    scrollToRelease = () => {
      const { location } = this.props;
      if (location.state && location.state.scrollRelease) {
        console.log("scrollToRelease", location.state.scrollRelease);
        setTimeout(()=>{
          let el = document.getElementById(`release${location.state.scrollRelease}`);
          if (el) {
              el.scrollIntoView();
          }
        }, 500);
      }
    }

    getContent = () => {
        request(
            `${process.env.REACT_APP_API}/1/cms/contentpage/releases`
        ).then((data) => {
            // console.log(data);
            this.setState({page: data});
        }).catch((error) => {
            console.error(error);
        });
    }

    render() {
        const {list, isLoading} = this.props;
        const {page} = this.state;
        return (
            <div className="page releases container px-3 px-md-5">
                <Breadcrumb>Releases</Breadcrumb>
                <Banner imageUrl={page.imageUrl}><h2>{page.title}</h2></Banner>
                {page.bodyContent && <>
                    <div dangerouslySetInnerHTML={{__html: page.bodyContent}} />
                    <hr className="my-5" />
                </>}

                {list && list.length > 0 && <div className="list releases">
                    <div className="row product-row">
                        {list.map(release => {
                            return (
                                <div key={release.slug}  className="col-6 col-sm-4 col-md-3" id={`release${release.slug}`}>
                                    <ReleaseListItem release={release} />
                                </div>
                            );
                        })}
                    </div>
                    {isLoading && <Loader type="placeholder" />}
                    {this.props.error && <p className="text-danger py-3">{this.props.error}</p>}
                    {list.length < this.props.count && <div className="text-center mb-5">
                        <Button onClick={() => { this.props.getReleases(this.props.page + 1); }}>Load more</Button>
                    </div>}
                </div>}
          </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Releases));
