import { useState, useEffect, useRef } from "react";
import SimpleReactLightbox, { SRLWrapper, useLightbox } from "simple-react-lightbox";
import coverFallback from '../../nocover.png';
import { request } from '../../functions/apiRequestWrapper';
import cn from "classnames";
import Spinner from 'react-bootstrap/Spinner';

const lbOptions = {
  buttons: {
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
    showAutoplayButton: false,
  }
};

const InternalPreview = ({ buttonText, folderName, isbn }) => {
  
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const timeoutRef = useRef(null);
  const { openLightbox } = useLightbox();
  
  const onFirstImgLoad = () => {
    // console.log("image loaded");
    setLoading(false);
    clearTimeout(timeoutRef.current);
  }
  const onFirstImgError = () => {
    // console.log("image error");
    setHasError(true);
    setLoading(false);
    clearTimeout(timeoutRef.current);
  }
  
  useEffect(() => {
    request(
      `${process.env.REACT_APP_API}/1/service/isbn/${isbn}?folderName=${folderName}`
    ).then((data) => {
      // console.log("internal", data);
      setImages(data);
      timeoutRef.current = setTimeout(() => {
        console.log("image timeout", buttonText);
        setHasError(true);
        setLoading(false);
        clearTimeout(timeoutRef.current);
      }, 10000);
    }).catch((error) => {
      // console.error(error);
      setHasError(true);
      setLoading(false);
    });
    return () => {
      clearTimeout(timeoutRef.current);
    }
    // eslint-disable-next-line
  }, []);
  
  return (
    <>
    <button type="button" className={cn("btn btn-primary mr-2 mb-2", {"d-none": hasError, "loading": loading})} disabled={loading} onClick={openLightbox}>
        <span className="btntext">{buttonText}</span>
        {loading && <Spinner animation="border" role="status" variant="white" size="sm" />}
    </button>
    <div style={{ display: 'none' }}>
    <SRLWrapper options={lbOptions}>
        {images?.map(function (img, i) {
          if (i === 0) {
            return <img key={i} src={img} alt="" onLoad={onFirstImgLoad} onError={onFirstImgError} />;
          }
          return <img key={i} src={img} alt="" />;
        })}
    </SRLWrapper>
    </div>
    </>
  );
}

const CoverPreview = ({ imageUrl }) => {
  const { openLightbox } = useLightbox();
  return (
    <>
    <img src={imageUrl} onError={(e)=>{e.target.onerror = null; e.target.src = coverFallback;}} alt="book cover preview" onClick={openLightbox} />
    <div style={{ display: 'none' }}>
    <SRLWrapper options={lbOptions}>
        <img src={imageUrl} alt="Cover" />
    </SRLWrapper>
    </div>
    </>
  );
}

const BookCoverWithPreview = ({ isbn, isOffline, imageUrl, onClickVideo, hasImages, hasMarketing }) => {

  return (
    <div className="pt-5 pt-md-0 book-cover">
      <div className="book-cover-wrapper">
        {imageUrl &&
          <SimpleReactLightbox><CoverPreview imageUrl={imageUrl} /></SimpleReactLightbox>
        }
        {!imageUrl &&
          <img src={coverFallback} alt="book cover preview" />
        }
        <div className="mt-2">
          {hasImages && !isOffline &&
            <SimpleReactLightbox>
              <InternalPreview buttonText="View Inside" folderName="internal_images" isbn={isbn} />
            </SimpleReactLightbox>
          }
          {hasMarketing && !isOffline &&
            <SimpleReactLightbox>
              <InternalPreview buttonText="Marketing" folderName="marketing" isbn={isbn} />
            </SimpleReactLightbox>
          }
          {(typeof onClickVideo === 'function') &&
            <button type="button" className="btn btn-primary mr-2 mb-2" onClick={(e) => { e.preventDefault(); onClickVideo(); }}>Video</button>
          }
        </div>
      </div>
    </div>
  );
}

export default BookCoverWithPreview;
